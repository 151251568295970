import React from 'react';
import {Form, Input, Select} from 'antd';

const { Option } = Select;

type Props = {
    setSubject: Function,
    setAssign: Function,
    usersNoCrm: object[]
}

export const NoCrmAltyor: React.FC<Props> = ({ setSubject, setAssign, usersNoCrm}) => {
    return (
        <div className='no-crm-altyor'>
            <Form.Item label="Assign to:" name={['user', 'assignTo']} rules={[{ required: true }]}>
                <Select onSelect={value => setAssign(value)} className="assignTo" defaultValue="Choose" >
                    {
                        usersNoCrm ? usersNoCrm.map((user: any) => (<Option value={user.id}>{`${user.firstname} ${user.lastname}`}</Option>)) : null
                    }
                </Select>
            </Form.Item>
            <Form.Item name={['user', 'subject']} label="Subject" rules={[{ required: true }]}>
                <Input style={{borderColor: '#FF8900'}} onChange={event => setSubject(event.target.value)}/>
            </Form.Item>
        </div>
    );
};
