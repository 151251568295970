import React, {useState} from 'react';
import { Form, Input, Radio , Switch, Typography, Button, Row, Col, Select, Alert } from 'antd';
// @ts-ignore
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { NoCrmNodon } from "./noCrmNodon/NoCrmNodon";
import './NodonForm.scss';
import {postNodonMember, postNodonNoCrm, postNotifNodon, getEmailNodon} from "../../api/API";
import {createNodonMember, createNodonNoCrm, CreateNotification} from '../../types/@types';
import ReactGA from 'react-ga';

const { Option } = Select;
const Title = Typography;

type Props = {
    interestNodon: object[],
    usersNodonNoCrm: object[],
    nodonTags: object[],
    nodonMergeFields: any,
    nodonLanguage: object[],
    apiKey: string
}

export const NodonForm: React.FC <Props> = ({ apiKey, nodonLanguage, interestNodon, usersNodonNoCrm, nodonTags, nodonMergeFields}) => {

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [title, setTitle] = useState<string>();
    const [EMAIL, setEMAIL] = useState<string>();
    const [company, setCompany] = useState<string>();
    const [jobTitle, setJobTitle] = useState<string>();
    const [language, setLanguage] = useState<string>();
    const [landline, setLandline] = useState<string>();
    const [landlinePrefix, setLandlinePrefix] = useState<string>();
    const [mobile, setMobile] = useState<string>();
    const [mobilePrefix, setMobilePrefix] = useState<string>();
    const [comment, setComment] = useState<string>();
    const [keyWord] = useState<Array<string>>([]);
    const [noCrm, setNoCrm] = useState(false);
    const [subject, setSubject] = useState<string>();
    const [assign, setAssign] = useState<number>();
    const [source, setSource] = useState();
    const [crmComment, setCrmComment] = useState<string>();
    const [status, setStatus] = useState<string>();
    const [doNotDisturb, setDoNotDisturb] = useState<boolean>(false);
    const [interestId] = useState<Array<string>>([]);
    const [interestName] = useState<Array<string>>([]);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [languageID, setLanguageID] = useState<string>();
    const [DNDID, setDNDID] = useState<string>('f2fe999010');
    const [errorEmail, setErrorEmail] = useState<boolean>(false);
    const [errorInput, setErrorInput] = useState<boolean>(false);


    const gaEvent = (category: string, action: string) => {
        ReactGA.event({
            category: category,
            action: action
        });
    };

    const emailIsInclude = async (email: any) => {
        let isInclude = await getEmailNodon(apiKey, email);
        if (isInclude === 0) {
            return false
        } else {
            return true
        }
    }

    const upperCase = (string: string) => {
        let array = string.split('');
        array.splice(0, 1, array[0].toUpperCase());
        return array.join('')
    }

    const createObject = () => {
        let myObject: object = {}
        for (let i = 0 ; i < interestId.length; i++) {
           let key = `${interestId[i]}`;
            // @ts-ignore
            myObject[key] = true;
        }
        return myObject
    }

    const NodonUser = () => {
        let completeMobile = mobile === undefined ? '' : `+${mobilePrefix}${mobile}`;
        let completeLandline = landline === undefined ? '' : `+${landlinePrefix}${landline}`;
        let interestObject = createObject();
        let keyLanguage = `${languageID}`;
        let keyDND = `${DNDID}`
        // @ts-ignore
        interestObject[keyLanguage] = true;
        // @ts-ignore
        interestObject[keyDND] = true;

        let nodonUser = {
            status: doNotDisturb ? 'unsubscribed' : 'subscribed',
            email_address: EMAIL || '',
            merge_fields: {
                PRENOM: firstName || '',
                NOM: lastName || '',
                ENTREPRISE: company,
                FONCTION: jobTitle,
                TELEPHONE: completeLandline,
                MOBILE: completeMobile,
                COMMENTAIR: comment,
                SOURCE: source || '',
                STATUT: status || '',
                MMERGE12: title || ''
            },
            interests: interestObject,
        }
        return nodonUser
    };

    const NodonNoCrm = () => {
        let completeMobile = `+${mobilePrefix}${mobile}`;
        let completeLandline = `+${landlinePrefix}${landline}`;
        let newTitle = `${title} ${lastName}`;
        let userNoCrm = {
            title: newTitle || '',
            description :
                `Company:${' '}${company}\n
                  First name:${' '}${firstName}\n
                  Last name:${' '}${lastName}\n
                  E-mail:${' '}${EMAIL}\n
                  Language:${' '}${language}\n
                  Telephone:${' '}${completeLandline}\n
                  Mobile:${' '}${completeMobile}\n
                  Source:${' '}${source}\n
                  Statut:${' '}${status}\n
                  Interest:${' '}${interestName}\\n
                  Subject:${' '}${subject}\n
                  Do not disturb:${' '}${doNotDisturb ? 'unsubscribed' : 'subscribed'}\\n
                  Comments:${' '}${crmComment}`,
            tags : keyWord,
            created_by_id: assign,
            user_id: assign
        }
        return userNoCrm
    };

    const dataNotif = () => {
        let newFirstName = upperCase(firstName);
        let newLastName = upperCase(lastName);
        let data = {
            name : newFirstName || ' ',
            surname: newLastName || ' ',
            email: EMAIL || ' ',
            NoCrm: noCrm ? 'true' : 'false',
            company: company || ' ',
            title: title || ' ',
            category: 'N/A'
        }
        return data
    }

    const sentToApi = () => {
        if (noCrm) {
            postNodonNoCrm(createNodonNoCrm(NodonNoCrm()),apiKey)
            postNodonMember(createNodonMember(NodonUser()),apiKey)
            postNotifNodon(CreateNotification(dataNotif()),apiKey)
        } else {
            postNodonMember(createNodonMember(NodonUser()),apiKey)
            postNotifNodon(CreateNotification(dataNotif()),apiKey)
        }
    }

    const [country] = useState()
    const arrayCountries : any = en;
    const prefixLandline = () => (
        <Form.Item name="prefix" noStyle>
            <Select defaultValue="Prefix" onSelect={value => {
                setLandlinePrefix(getCountryCallingCode(value))
                setMobilePrefix(getCountryCallingCode(value))
            }} value={country}>
                {getCountries().map((country: string) => (
                    <Option key={country} value={country}>
                        {arrayCountries[country]} +{getCountryCallingCode(country)}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );

    return (
        <Row className="display-form-nodon">
            <Col span={24} className="container-form-nodon">
                <Title style={{fontSize: '2.4em', color: 'white'}}>Information</Title>
                <Form>
                    <Form.Item name={['user', 'title']} label="Title" rules={[{ required: true }]}>
                        <Select onSelect={value => setTitle(value)} defaultValue="Select title" className="select-after">
                            <Option value="Monsieur">Monsieur</Option>
                            <Option value="Madame">Madame</Option>
                            <Option value="Mr.">Mr.</Option>
                            <Option value="Mrs.">Mrs.</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={['user', 'lastName']} label="Last name:" rules={[{ required: true }]}>
                        <Input onChange={event => setLastName(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'firstName']} label="First name:" rules={[{ required: true }]}>
                        <Input onChange={event => setFirstName(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'email']} label="Email:" rules={[{ type: 'email', required: true }]}>
                        <Input onChange={event => setEMAIL(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'company']} label="Company:" rules={[{ required: true }]}>
                        <Input onChange={event => setCompany(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'jobTitle']} label="Job title:" rules={[{ required: true }]}>
                        <Input onChange={event => setJobTitle(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'comment']} label="Comment:">
                        <Input onChange={event => setComment(event.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        name={['user', 'landline']}
                        label="Landline:"
                        rules={[{ message: 'Please input your phone number!' }]}
                    >
                        <Input
                            addonBefore={prefixLandline()}
                            onChange={event => setLandline(event.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['user', 'mobile']}
                        label="Mobile:"
                        rules={[{ message: 'Please input your mobile number!' }]}
                    >
                        <Input
                            addonBefore={prefixLandline()}
                            onChange={event => setMobile(event.target.value)}
                        />
                    </Form.Item>
                    <div className='select-language'>
                        <Title style={{fontSize: '1.2em', color: 'white'}}>Language:</Title>
                        <Radio.Group style={{display: 'flex', width: '75%', justifyContent: 'space-around'}}>
                            {nodonLanguage ? nodonLanguage.map((language: any) => <Radio value={language.id} onChange={() => {
                                setLanguageID(language.id);
                                setLanguage(language.name);
                            }} >{language.name}</Radio >) : null}
                        </Radio.Group>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', flexWrap: 'wrap'}}>
                        <Title style={{fontSize: '1.6em', color: 'white', textAlign: 'center'}}>Relationship</Title>
                        <div style={{display: 'flex', width: '100%', flexWrap: 'wrap'}}>
                            <Form.Item label="Sources:" rules={[{ required: true }]}>
                                <Select onSelect={value => setSource(value)} defaultValue="Select..." className="select-before">
                                    {nodonMergeFields ? nodonMergeFields[11].options.choices.map((element: any) => (
                                        <Option value={element}>{element}</Option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Status:" rules={[{ required: true }]}>
                                <Select onSelect={value => setStatus(value)} defaultValue="Select..." className="select-before">
                                    {nodonMergeFields ? nodonMergeFields[12].options.choices.map((element: any) => (
                                        <Option value={element}>{element}</Option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{width: '100%', margin: '2%'}}>
                            <Title style={{fontSize: '1.2em',color: 'white'}}>Interest:</Title>
                            <div style={{width: '100%'}}>
                                {interestNodon ? interestNodon.map((interest: any) => (<Radio onChange={e => {
                                    interestId.push(e.target.value);
                                }} value={interest.id}>{interest.name}</Radio >)) : null}
                            </div>
                            <Title style={{fontSize: '1.2em',color: 'white'}}>Do not disturb:</Title>
                            <Radio.Group defaultValue={2} style={{display: 'flex', width: '100%'}}>
                                <Radio value={1} onChange={() => {
                                    setDoNotDisturb(true);
                                    setDNDID('8baf0d034f');
                                }} >Yes</Radio >
                                <Radio value={2} onChange={() => {
                                    setDoNotDisturb(false);
                                    setDNDID('f2fe999010');
                                }} >No</Radio >
                            </Radio.Group>
                        </div>
                    </div>
                    <span style={{width: '100%', display: 'flex', alignItems: 'center', margin: '2%'}}>
                    <Title style={{fontSize: '1.6em', color: 'white'}}>NoCrm</Title> <Switch style={{margin: '2%'}} onChange={() => setNoCrm(!noCrm)}/>
                </span>
                    <div style={{width: '100%'}}>
                        {noCrm ? <NoCrmNodon usersNodonNoCrm={usersNodonNoCrm} nodonTags={nodonTags} keyWord={keyWord} setCrmComment={setCrmComment} setSubject={setSubject} setAssign={setAssign}/> : null}
                    </div>
                </Form>
                {success ? <Alert message="Success contact add." type="success" showIcon/> : null}
                {error ? <Alert message="Error the contact is not added." type="error" showIcon/> : null}
                {errorEmail ? <Alert message="Error email already known ." type="error" showIcon/> : null}
                {errorInput ? <Alert message="Error input is empty ." type="error" showIcon/> : null}
                <Button style={{backgroundColor: '#FF8900', color: 'white', borderColor: '#FF8900', margin: '2%'}} onClick={async () => {
                    try {
                        if (!await emailIsInclude(EMAIL)) {
                            if (!source || !status) {
                                setErrorInput(true)
                                setTimeout(() => setErrorInput(false), 3000);
                            } else {
                                sentToApi()
                                setSuccess(true);
                                gaEvent('Sending', 'The Nodon contact is sending');
                                setTimeout(() => {
                                    setSuccess(false);
                                    window.location.reload(true)
                                },3000 );
                            }
                        } else {
                            setErrorEmail(true)
                        }
                    } catch (e) {
                        setError(true);
                        gaEvent('Not sending', 'The Nodon contact isn\'t sending');
                        setTimeout(() => setError(false), 3000);
                    }
                }}>Submit</Button>
            </Col>
        </Row>
    );
};

