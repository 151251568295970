import React from 'react';
import {Form, Radio , Typography} from 'antd';
const Title = Typography;

type Props = {
    active: boolean,
    setActive: Function,
    setSizes: Function,
    altyorSizes:object[],
    altyorSources: object[],
    setSource: Function,
    setNoCrmSizes: Function,
    setNoCrmSource: Function
}

export const CustomerForm: React.FC <Props> = ({setNoCrmSource, setNoCrmSizes, setSource, altyorSources ,altyorSizes, active, setActive, setSizes}) => {
    return (
        <Form>
            <div className="customer-container">
                <div style={{display: 'flex',flexDirection: 'column', width: '100%'}}>
                    <Title style={{fontSize: '1.4em', color: 'white'}}>Active:</Title>
                    <Radio.Group defaultValue={1}>
                        <Radio value={1} onChange={() => setActive(!active)} >Yes</Radio >
                        <Radio value={2} onChange={() => setActive(!active)} >No</Radio >
                    </Radio.Group>
                </div>
                <div style={{display: 'flex',flexDirection: 'column', width: '100%'}}>
                    <Title style={{fontSize: '1.4em', color: 'white'}}>Sizes:</Title>
                    <Radio.Group>
                        {
                            altyorSizes ? altyorSizes.map((size:any) =>  <Radio value={size.id} onChange={() => {
                                    setSizes(size.id);
                                    setNoCrmSizes(size.name);
                                }} >{size.name}</Radio >)
                                : null
                        }
                    </Radio.Group>
                </div>
                <div style={{display: 'flex',flexDirection: 'column', width: '100%'}}>
                    <Title style={{fontSize: '1.4em', color: 'white'}}>Sources:</Title>
                    <Radio.Group>
                        {altyorSources ?
                            altyorSources.map((source: any) => <Radio value={source.id} onChange={() => {
                                setSource(source.id);
                                setNoCrmSource(source.name);
                            }} >{source.name}</Radio >)
                            :null
                        }
                    </Radio.Group>
                </div>
            </div>
        </Form>
    );
};

