import React, {useEffect, useState} from 'react';
import {Row, Button, Typography} from 'antd';
import { AltyorForm } from '../components/altyor.form/AltyorForm';
import {NodonForm} from "../components/nodon.form/NodonForm";
import {Header} from "../components/header/Header";
import './Home.scss';
import {Loading} from "../components/loading/Loading";
import {
    getCriteriasAltyor,
    getNodOnInterestsCategory,
    getNodOnMergeFields,
    getTagsNodonNoCrm,
    getUsersAltyorNoCrm,
    getUsersNodonNoCrm,
    getNodOnInterestsLanguage,
    getMailShotAltyor,
    getSourcesAltyor,
    getAltyorSizes
} from "../api/API";
import ReactGA from 'react-ga';

const Title = Typography;

type Props ={
    user: any
    apiKey: string
}

export const Home: React.FC <Props> = ({user, apiKey}) => {
    const [selectedForm, setSelectedForm] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [usersNoCrm, setUsersNoCrm] = useState();
    const [altyorSizes, setAltyorSizes] = useState();
    const [altyorSources, setAltyorSources] = useState();
    const [altyorMailShot, setAltyorMailShot] = useState();
    const [altyorCriterias, setAltyorCriterias] = useState();
    const [interestNodon, setInterestNodon] = useState();
    const [usersNodonNoCrm, setUsersNodonNoCrm] = useState();
    const [nodonTags, setNodonTags] = useState();
    const [nodonMergeFields, setNodonMergeFields] = useState()
    const [nodonLanguage, setNodonLanguage] = useState()

    const gaEvent = (category: string, action: string) => {
        ReactGA.event({
            category: category,
            action: action
        });
    };

    useEffect(() => {
        (async () => {
            try {
                gaEvent('Login', 'The user is connected');
                setAltyorMailShot(await getMailShotAltyor(apiKey));
                setAltyorSources(await getSourcesAltyor(apiKey));
                setAltyorSizes(await getAltyorSizes(apiKey));
                setUsersNoCrm(await getUsersAltyorNoCrm(apiKey));
                setAltyorCriterias(await getCriteriasAltyor(apiKey));
                setInterestNodon(await getNodOnInterestsCategory(apiKey));
                setUsersNodonNoCrm(await getUsersNodonNoCrm(apiKey))
                setNodonMergeFields(await getNodOnMergeFields(apiKey));
                setNodonTags(await getTagsNodonNoCrm(apiKey));
                setNodonLanguage(await getNodOnInterestsLanguage(apiKey));
                setLoading(false)
            } catch (e) {
                alert('Error Serveur')
            }
        })();
    }, [apiKey]);

    return (
        <div style={{width: '100%', height: '100vh', display: 'flex'}}>
            <div className="background"/>
            {
                loading ? <div style={{position: 'absolute', display: 'flex',height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center'}}><Loading /></div>
                    : <div className="dashboard">
                    <Header user={user}/>
                    <Row style={{width: '100%', height: '70%'}}>
                        <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%', alignItems: 'center'}}>
                            <Title style={{color: 'white', fontSize: '2.4em'}}>{selectedForm ? 'Altyor' : 'NodOn'}</Title>
                            <Button style={{marginRight: 20, backgroundColor: '#FF8900', color: 'white', borderColor: '#FF8900'}} onClick={() => setSelectedForm(!selectedForm)}>{selectedForm ? 'NodOn' : 'Altyor'}</Button>
                        </div>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {selectedForm ? <AltyorForm altyorMailShot={altyorMailShot} altyorSources={altyorSources} altyorSizes={altyorSizes} apiKey={apiKey} usersNoCrm={usersNoCrm} altyorCriterias={altyorCriterias}/> : <NodonForm apiKey={apiKey} nodonLanguage={nodonLanguage} interestNodon={interestNodon} usersNodonNoCrm={usersNodonNoCrm} nodonTags={nodonTags} nodonMergeFields={nodonMergeFields}/>}
                        </div>
                    </Row>
                </div>
            }
        </div>

    );
};

