import React from 'react';
import { Home } from './views/Home';
import './App.scss';
import 'antd/dist/antd.css';
import {Route, BrowserRouter, Switch } from 'react-router-dom'
import { Row,Col,Card  } from 'antd'
import { Token} from './Token'
import {IUser} from './IUser'
import Cookies  from 'js-cookie';
import ReactGA from 'react-ga';

export interface ICredential {
  token: any
}

export class App extends React.Component<{}, {loading: boolean, isAuthenticated: boolean, user: IUser, apiKey: string}> {
  token = ""

  constructor(props : any) {
    super(props);
    let existingCookie = Cookies.get("token");
    if (existingCookie === undefined) {
      this.token = "";
      this.state = {
        apiKey: '',
        loading: false,
        isAuthenticated: false,
        user: {dn: "",cn: "",sn: "",title: "",givenName: "",displayName: "",memberOf: [],name: "",objectGUID: "",objectSid: "",sAMAccountName: "",mail: ""} as IUser,
      }
      console.log("cookie NOT found, token is :" + this.token)
    } else {
      this.token = existingCookie;
      this.state = {
          apiKey: '',
        loading: true,
        isAuthenticated: false,
        user: {dn: "",cn: "",sn: "",title: "",givenName: "",displayName: "",memberOf: [],name: "",objectGUID: "",objectSid: "",sAMAccountName: "",mail: ""} as IUser,
      }
    }
  this.isAuthenticated = this.isAuthenticated.bind(this)
  }

  componentDidMount() {
      ReactGA.initialize('UA-175588921-1');
      ReactGA.pageview('/home');
    if (this.token) {
      this.fetchLogin();
    }
  }

  isAuthenticated() {
    return (this.state.isAuthenticated)
  }

   fetchLogin = () => {
    console.log("fetch login")
    this.setState({loading: true});
    console.log(process.env.REACT_APP_URL_API_AUTH)
    fetch(process.env.REACT_APP_URL_API_AUTH! + this.token,{
          method:'GET',
        }).then(res => {
            console.log(res.status);
        if (res.ok) {
              res.json().then(json => {
                this.setState({isAuthenticated : true, loading: false, user: json.user, apiKey: json.apiKey[0].key})

              });
            } else {
              console.log(res.status);
              this.setState({isAuthenticated : false, loading: false})
            }
        }).catch(err => {
            this.setState({isAuthenticated : false, loading: false})
            console.log(err)}
)}

  render() {
    return (
          <BrowserRouter>
          <Switch>
            <Route exact path="/" render={() => <Token callback={this.setCredential} fetchLogin={this.fetchLogin} existingToken={this.token} />} />
            <PrivateRoute path="/home" children={<Home apiKey={this.state.apiKey} user={this.state.user}/>} isLoading={this.state.loading} isAuthenticated={this.state.isAuthenticated}/>

          </Switch>
          </BrowserRouter>
    );
  }

  setCredential = (newCredential : ICredential) => {
    console.log("Setting token ! : " + newCredential.token);
    this.token = newCredential.token;
    Cookies.set('token', this.token, {path: "/", expires: 5})
  }
}

const PrivateRoute = ({component,  isLoading, isAuthenticated, ...rest}: any) => {
    console.log("private route : loading is " + isLoading)

    if (isLoading) {
      return (<Row align="middle" style={{height:"100%"}}>
                <Col span={20} style={{textAlign: 'center', top:'50%', margin:'auto'}}>
                  <Card  bordered={false} loading={true} style={{textAlign: 'center'}}>
                  </Card>
                </Col>
              </Row>);
    }
    if (!isAuthenticated) {
      console.log("Not auth forward to gate")
      window.location.href = process.env.REACT_APP_URL_GATE_FE!
      return (<div></div>)
    }
    const routeComponent = (props: any) => (
        React.createElement(component, props)
    );
    return <Route {...rest} render={routeComponent}/>;
};

export default App;
