import React from 'react';
import {Form, Radio , Typography, Input} from 'antd';
const Title = Typography;

type Props = {
    criterias: string[],
    setComment: Function,
    setSizes: Function,
    setSource: Function,
    altyorCriterias: object[],
    altyorSources: object[],
    altyorSizes: object[],
    setNoCrmSizes: Function,
    setNoCrmSource: Function,
    noCrmCriterias: string[]
}

export const ProspectForm: React.FC <Props> = ({noCrmCriterias, setNoCrmSource, setNoCrmSizes, altyorSizes, altyorSources, altyorCriterias, criterias, setComment, setSizes, setSource}) => {
    return (
        <Form className="form-prospect">
            <div className="input-container-prospect">
                <Form.Item name={['user', 'projectDesciption']} label="Desciption" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={['user', 'comments']} label="Comments">
                    <Input onChange={event => setComment(event.target.value)}/>
                </Form.Item>
            </div>
            <div className="radio-container-prospect">
                <div style={{display: 'flex',width: '100%', flexDirection: 'column'}}>
                    <Title style={{fontSize: '1.4em', color: 'white'}}>Criterias:</Title>
                    <div className="criterias-container">
                        {
                            altyorCriterias ? altyorCriterias.map(
                                (element: any) => (<Radio style={{width: '40%'}} onChange={() => {
                                    criterias.includes(element.id) ? criterias.splice(criterias.indexOf(element.id),1) : criterias.push(element.id)
                                    noCrmCriterias.includes(element.name) ? noCrmCriterias.splice(noCrmCriterias.indexOf(element.name),1) : noCrmCriterias.push(element.name)
                                }} >{element.name}</Radio >)
                            ) : null
                        }
                    </div>
                </div>
                <div style={{display: 'flex',width: '100%', flexDirection: 'column'}}>
                    <Title style={{fontSize: '1.4em', color: 'white'}}>Sources:</Title>
                    <Radio.Group>
                        {altyorSources ?
                            altyorSources.map((source: any) => <Radio value={source.id} onChange={() => {
                                setSource(source.id);
                                setNoCrmSource(source.name);
                            }} >{source.name}</Radio >)
                            :null
                        }
                    </Radio.Group>
                </div>
                <div style={{display: 'flex',width: '100%', flexDirection: 'column'}}>
                    <Title style={{fontSize: '1.4em', color: 'white'}}>Sizes:</Title>
                    <Radio.Group>
                        {
                            altyorSizes ? altyorSizes.map((size: any) => <Radio value={size.id} onChange={() => {
                                    setSizes(size.id);
                                    setNoCrmSizes(size.name);
                                }} >{size.name}</Radio >)
                                : null
                        }
                    </Radio.Group>
                </div>
            </div>
        </Form>
    );
};

