import React from 'react';
import {Form, Radio , Typography} from 'antd';
const Title = Typography;

type Props = {
    setType: Function
}

export const SupplierForm: React.FC <Props> = ({setType}) => {
    return (
        <Form>
            <Title style={{fontSize: '1.4em', color: 'white'}}>Types:</Title>
            <Radio.Group>
                <Radio value={1} onChange={() => setType('Electronic')} >Electronic</Radio >
                <Radio value={2} onChange={() => setType('Mechanical')} >Mechanical</Radio >
                <Radio value={3} onChange={() => setType('Other')} >Other</Radio >
            </Radio.Group>
        </Form>
    );
};

