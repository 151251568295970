import React from 'react';
import {Form, Input, Radio, Select, Typography} from 'antd';

const { Option } = Select;
const Title = Typography;

type Props = {
    keyWord: string[],
    setSubject: Function,
    setCrmComment: Function,
    setAssign: Function,
    nodonTags: object[],
    usersNodonNoCrm: object[],
}

export const NoCrmNodon: React.FC<Props> = ({keyWord, setSubject, setCrmComment, setAssign, nodonTags,usersNodonNoCrm}) => {
    return (
        <div className="no-crm-nodon" style={{width: '100%', display: 'flex', margin: '2%'}}>
            <div className="keywords">
                <Title style={{fontSize: '1.2em', color: 'white'}}>Keywords:</Title>
                {nodonTags ? nodonTags.map((tag: any) => (
                    <Radio onChange={() => {keyWord.includes(tag.name) ? keyWord.splice(keyWord.indexOf(tag.name),1) : keyWord.push(tag.name)}}>{tag.name}</Radio >
                )) : null}
            </div>
            <div className='more-input'>
                <Form.Item style={{display: 'flex', flexDirection: 'column',width: '90%', margin: '2% 0px'}} label="Assign to:" name={['user', 'assignTo']}>
                    <Select defaultValue="Select..." onSelect={(value: any) => setAssign(value)} className="select-before">
                        {
                            usersNodonNoCrm ? usersNodonNoCrm.map((user: any) => <Option value={user.id}>{`${user.firstname} ${user.lastname}`}</Option>) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Subjet" style={{display: 'flex', flexDirection: 'column',width: '90%', margin: '2% 0px'}} name={['user', 'subject']}>
                    <Input onChange={event => setSubject(event.target.value)}/>
                </Form.Item>
                <Form.Item style={{display: 'flex', flexDirection: 'column',width: '90%', margin: '2% 0px'}} name={['user', 'comments']} label="Comment">
                    <Input onChange={event => setCrmComment(event.target.value)}/>
                </Form.Item>
            </div>
        </div>
    );
};
