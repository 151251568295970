import React from 'react';
import {Form, Radio , Typography} from 'antd';
const Title = Typography;

type Props = {
    setType: Function
}

export const PartnerForm: React.FC <Props> = ({setType}) => {
    return (
        <Form>
            <Title style={{fontSize: '1.4em', color: 'white'}}>Types:</Title>
            <Radio.Group>
                <Radio value={1} onChange={() => setType('IT & software')} >IT & software</Radio >
                <Radio value={2} onChange={() => setType('AI')} >AI</Radio >
                <Radio value={3} onChange={() => setType('Incubators & accelerators')} >Incubators & accelerators</Radio >
                <Radio value={4} onChange={() => setType('Finance & investors')} >Finance & investors</Radio >
                <Radio value={5} onChange={() => setType('Associations & foundations')} >Associations & foundations</Radio >
                <Radio value={6} onChange={() => setType('Business & marketing')} >Business & marketing</Radio >
                <Radio value={7} onChange={() => setType('Consultancy')} >Consultancy</Radio >
                <Radio value={8} onChange={() => setType('Other')} >Other</Radio >
            </Radio.Group>
        </Form>
    );
};

