import React from 'react';
import ReactLoading from 'react-loading';

export const Loading: React.FC = () => {
    return (
        <div>
            <ReactLoading type="spinningBubbles" color="#FF8900"/>
        </div>
    );
};
