import axios from 'axios';

//Altyor Request

export const postMemberAltyor = (data: any, apiKey: string) => {
    axios.post(`${process.env.REACT_APP_ALTYOR_MEMBERS}`, data, {
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => console.log(response.status))
        .catch((error: any) => {
            console.log(error)
        })
}

export const postMemberNoCrm = (data: any, apiKey: string) => {
    axios.post(`${process.env.REACT_APP_ALTYOR_LEADS}`, data, {
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => console.log(response.status))
        .catch((error: any) => {
            console.log(error)
        })
}

export const postNotifAltyor = (data: any, apiKey: string) => {
    axios.post(
        `https://api.lead.altyor.solutions/v1/slack-notification/altyor?name=${data.name}&surname=${data.surname}&email=${data.email}&company=${data.company}&nocrm=${data.NoCrm}&title=${data.title}&category=${data.category}`, data, {
            headers: {
                'x-api-key': `${apiKey}`
            }
        })
        .then((response: any) => console.log(response.status))
        .catch((error: any) => {
            console.log(error)
        })
}

export const getUsersAltyorNoCrm = (apiKey: string) => {
    return axios.get(`${process.env.REACT_APP_ALTYOR_NOCRM_URL}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data)
        .catch((error: any) => {
            console.log(error)
        })
}

export const getMailShotAltyor = (apiKey: string) => {
    return axios.get(`${process.env.REACT_APP_ALTYOR_MAILSHOT}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data.interests)
        .catch((error: any) => {
            console.log(error)
        })
}

export const getSourcesAltyor = (apiKey: string) => {
    return axios.get(`${process.env.REACT_APP_ALTYOR_SOURCES}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data.interests)
        .catch((error: any) => {
            console.log(error)
        })
}

export const getAltyorSizes = (apiKey: string) => {
    return axios.get(`${process.env.REACT_APP_ALTYOR_SIZES}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data.interests)
        .catch((error: any) => {
            console.log(error)
        })
}

export const getCriteriasAltyor = (apiKey: string) => {
    return axios.get(`${process.env.REACT_APP_ALTYOR_CRITERIAS}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data.interests)
        .catch((error: any) => {
            console.log(error)
        })
}

//Nodon Request

export const postNodonMember = (data: any, apiKey: string) => {
    axios.post(`${process.env.REACT_APP_NODON_MEMBERS}`, data, {
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => console.log(response.status))
        .catch((error: any) => {
            console.log(error)
        })
}

export const postNodonNoCrm = (data: any, apiKey: string) => {
    axios.post(`${process.env.REACT_APP_NODON_LEADS}`, data, {
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => console.log(response.status))
        .catch((error: any) => {
            console.log(error)
        })
}

export const postNotifNodon = (data: any, apiKey: string) => {
    axios.post(
        `https://api.lead.altyor.solutions/v1/slack-notification/nodon?name=${data.name}&surname=${data.surname}&email=${data.email}&company=${data.company}&nocrm=${data.NoCrm}&title=${data.title}&category=${data.category}`, data, {
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => console.log(response.status))
        .catch((error: any) => {
            console.log(error)
        })
}

export const getNodOnInterestsLanguage = (apiKey: string) => {
    return axios.get(`${process.env.REACT_APP_NODON_LANGUAGES}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data.interests)
        .catch((error: any) => {
            console.log(error)
        })
}

export const getNodOnInterestsCategory = (apiKey: string) => {
    return axios.get(`${process.env.REACT_APP_NODON_INTEREST}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data.interests)
        .catch((error: any) => {
        console.log(error)
    })
}

export const getNodOnMergeFields = (apiKey: string) => {
    return axios.get(`${process.env.REACT_APP_NODON_MERGE_FIELD}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data.merge_fields)
        .catch((error: any) => {
            console.log(error)
        })
}

export const getTagsNodonNoCrm = (apiKey: string) => {
    return axios.get(`${process.env.REACT_APP_NOCRM_NODON_GET_TAGS}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data)
        .catch((error: any) => {
            console.log(error)
        })
}

export const getUsersNodonNoCrm = (apiKey: string) => {
    return axios.get('https://api.lead.altyor.solutions/v1/nocrm/nodon/users',{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data)
        .catch((error: any) => {
            console.log(error)
        })
}

export const getEmailNodon = (apiKey: string, email: string) => {
    return axios.get(`https://api.lead.altyor.solutions/v1/mailchimp/nodon/members?email=${email}`,{
        headers: {
            'x-api-key': `${apiKey}`
        }
    })
        .then((response: any) => response.data.exact_matches.total_items)
        .catch((error: any) => {
            console.log(error)
        })
}

