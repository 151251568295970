import React from 'react';
import {Form, Radio , Typography} from 'antd';
const Title = Typography;

type Props = {
    setType: Function,
}

export const PressForm: React.FC <Props> = ({ setType }) => {
    return (
        <Form>
            <Title style={{fontSize: '1.4em', color: 'white'}}>Types :</Title>
            <Radio.Group>
                <Radio value={1} onChange={() => setType('IoT')} >IoT</Radio >
                <Radio value={2} onChange={() => setType('Eco-responsibilty')} >Eco-responsibilty</Radio >
                <Radio value={3} onChange={() => setType('Management & processes')} >Management & processes</Radio >
                <Radio value={4} onChange={() => setType('Business & strategy')} >Business & strategy</Radio >
                <Radio value={5} onChange={() => setType('Other')} >Other</Radio >
            </Radio.Group>
        </Form>
    );
};

