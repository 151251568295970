import React, {useState} from 'react';
import { Form, Input, Radio , Switch, Typography, Button, Row, Col, Select, Alert } from 'antd';
// @ts-ignore
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json'
import './AltyorForm.scss';
import {ProspectForm} from './prospect/ProspectForm';
import {CustomerForm} from './customer/CustomerForm';
import {PartnerForm} from './partner/PartnerForm';
import {SupplierForm} from './supplier/SupplierForm';
import {PressForm} from './press/PressForm';
import {NoCrmAltyor} from "./noCrm/NoCrmAltyor";
import {languages} from "../../data/languages";
import {
    postMemberNoCrm,
    postMemberAltyor,
    postNotifAltyor
} from '../../api/API';
import {createMemberMailChimp, createMemberCrm, CreateNotification} from '../../types/@types';
import ReactGA from 'react-ga';

const { Option } = Select;
const Title = Typography;

type Props = {
    usersNoCrm: object[],
    altyorCriterias: object[],
    apiKey: string,
    altyorSizes: object[],
    altyorSources: object[],
    altyorMailShot: object[]
}

export const AltyorForm :React.FC <Props> = ({altyorSizes, altyorSources, altyorMailShot, apiKey, usersNoCrm, altyorCriterias}) => {
    const [additionalForm, setAdditionalForm] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [EMAIL, setEMAIL] = useState<string>();
    const [town, setTown] = useState<string>();
    const [company, setCompany] = useState<string>();
    const [webSite, setWebSite] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [jobTitle, setJobTitle] = useState<string>();
    const [language, setLanguage] = useState<string>();
    const [landline, setLandline] = useState<string>();
    const [landlinePrefix, setLandlinePrefix] = useState<string>();
    const [mobile, setMobile] = useState<string>();
    const [mobilePrefix, setMobilePrefix] = useState<string>();
    const [mailShort, setMailShort] = useState<string>()
    const [mailShortName, setMailShortName] = useState<string>()
    const [category, setCategory] = useState<string>();
    const [noCrm, setNoCrm] = useState<boolean>(false);
    const [subject, setSubject] = useState<string>();
    const [assign, setAssign] = useState<number>();
    const [criterias] = useState<Array<string>>([]);
    const [comment, setComment] = useState<string>();
    const [sizes, setSizes] = useState<string>();
    const [source, setSource] = useState<string>();
    const [active, setActive] = useState<boolean>(false);
    const [type, setType] = useState<string>();
    const [noCrmSizes, setNoCrmSizes] = useState<string>();
    const [noCrmCriterias] = useState<Array<string>>([]);
    const [noCrmSource, setNoCrmSource] = useState<Array<string>>();

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const upperCase = (string: string) => {
        let array = string.split('');
        array.splice(0, 1, array[0].toUpperCase());
        return array.join('')
    }

    const gaEvent = (category: string, action: string) => {
        ReactGA.event({
            category: category,
            action: action
        });
    };

    const createAltyorUser = () => {
        let interest = {};
        let newFirstName = upperCase(firstName);
        let newLastName = upperCase(lastName);
        let completeLandline = landline === undefined ? '' :`+${landlinePrefix}${landline}`;
        let Phone = mobile === undefined ? '' : `+${mobilePrefix}${mobile}`;
        let sourceKey = `${source}`;
        let sizeKey = `${sizes}`;
        let mailShotKey = `${mailShort}`;

        for (let i = 0; i < criterias.length; i++) {
            let key = `${criterias[i]}`;
            // @ts-ignore
            interest[key] = true;
        }
        // @ts-ignore
        interest[mailShotKey] = true;

        if (category === 'Prospect' || category === 'Customer') {
            // @ts-ignore
            interest[sourceKey] = true;
            // @ts-ignore
            interest[sizeKey] = true;
        }

        let altyorUser = {
            status: mailShortName === 'Yes' ? 'subscribed' : 'unsubscribed',
            email_address: EMAIL || '',
            merge_fields: {
                TITLE: title || '',
                FIRSTNAME: newFirstName || '',
                LASTNAME: newLastName || '',
                COMPANY: company || '',
                WEBSITE: webSite === undefined ? '' : `${webSite}`,
                JOBTITLE: jobTitle || '',
                LANGUAGE: language || '',
                LANDLINE: `${completeLandline}`,
                TOWN: town || '',
                PHONE: `${Phone}`,
                CATEGORY: category,
                TYPE: type || '',
                COMMENT: comment || '',
                PTITLE: `${company}-${jobTitle}` || '',
                ACTIVE: active ? 'Yes' : 'No',
            },
            interests: interest,
        }
        return altyorUser
    }

    const createAltyorUserNoCrm = () => {
        let newFirstName = upperCase(firstName);
        let newLastName = upperCase(lastName);
        let WebSite = `${webSite}`;
        let completeLandline = `+${landlinePrefix}${landline}`;
        let Phone = `+${mobilePrefix}${mobile}`
        let altyorUserNoCrm = {
            "title": `${company}-${subject}` || ' ',
            "description":`
                Title: ${title}\n
                E-mail: ${EMAIL}\n
                First name: ${newFirstName}\n
                Last name: ${newLastName}\n
                MailShot: ${mailShort ? 'Yes' : 'No'}\n
                Language: ${language}\n
                Job Tile: ${(jobTitle === undefined ? '' : jobTitle)}\n
                Mobile: ${Phone}\n
                Landline: ${completeLandline}\n
                Web site: ${WebSite}\n
                Town: ${town}\n
                Project description: ${company}-${subject}\n
                Criterias: ${noCrmCriterias}\n
                Comment: ${comment}\n
                Sizes: ${noCrmSizes}\n
                Sources: ${noCrmSource}\n
                Customer active: ${active ? 'Yes' : 'No'}\n
                Type: ${type}\n
            `,
            "created_by_id": assign,
            "user_id": assign,
            "tags":category || ' ',
        }
        return altyorUserNoCrm
    }

    const dataNotif = () => {
        let newFirstName = upperCase(firstName);
        let newLastName = upperCase(lastName);
        let data = {
            name : newFirstName || ' ',
            surname: newLastName || ' ',
            email: EMAIL || ' ',
            NoCrm: noCrm ? 'true' : 'false',
            company: company || ' ',
            title: title || ' ',
            category: category || ' '
        }
        return data
    }

    const sentToApi = () => {
        if (noCrm) {
            postMemberAltyor(createMemberMailChimp(createAltyorUser()), apiKey);
            postMemberNoCrm(createMemberCrm(createAltyorUserNoCrm()), apiKey);
            postNotifAltyor(CreateNotification(dataNotif()), apiKey);
        } else {
            postMemberAltyor(createMemberMailChimp(createAltyorUser()),apiKey);
            postNotifAltyor(CreateNotification(dataNotif()),apiKey);
        }
    }

    const showAdditionalForm = (category: string) => {
        if (category === 'Prospect') {
           return <ProspectForm noCrmCriterias={noCrmCriterias} setNoCrmSource={setNoCrmSource} setNoCrmSizes={setNoCrmSizes} altyorSources={altyorSources} altyorCriterias={altyorCriterias} altyorSizes={altyorSizes} criterias={criterias} setComment={setComment} setSizes={setSizes} setSource={setSource}/>
        }
        if (category === 'Customer') {
            return <CustomerForm setNoCrmSizes={setNoCrmSizes} setNoCrmSource={setNoCrmSource} setSource={setSource} altyorSources={altyorSources} altyorSizes={altyorSizes} active={active} setActive={setActive} setSizes={setSizes}/>
        }
        if (category === 'Partner') {
            return <PartnerForm setType={setType}/>
        }
        if (category === 'Supplier') {
            return <SupplierForm setType={setType}/>
        }
        if (category === 'Press') {
            return <PressForm setType={setType}/>
        }
        if (category === 'HR') {
            return false
        }
        if (category === 'Other') {
            return false
        }
        if (category === '') {
            return false
        }
    };

    const [country] = useState()
    const arrayCountries : any = en;
    const prefixLandline = () => (
        <Form.Item name="prefix" noStyle>
            <Select onSelect={value => {
                setLandlinePrefix(getCountryCallingCode(value))
                setMobilePrefix(getCountryCallingCode(value))
            }} defaultValue="Select prefix" value={country}>
                {getCountries().map((country: string) => (
                    <Option key={country} value={country}>
                        {arrayCountries[country]} +{getCountryCallingCode(country)}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );

    return (
        <Row className="display-altyor">
            <Col span={24} className="container-form-altyor">
                <Title style={{fontSize: '2.4em', color: 'white'}}>Information</Title>
                <Form>
                    <Form.Item  name={['user', 'title']} label='Title' rules={[{ required: true }]}>
                        <Select onSelect={value => setTitle(value)} defaultValue="Select title" className="select-after">
                            <Option value="Monsieur">Monsieur</Option>
                            <Option value="Madame">Madame</Option>
                            <Option value="Mr.">Mr.</Option>
                            <Option value="Mrs.">Mrs.</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={['user', 'firstName']} label="First name" rules={[{ required: true }]}>
                        <Input onChange={event => setFirstName(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'lastName']} label="Last name" rules={[{ required: true }]}>
                        <Input onChange={event => setLastName(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email', required: true }]}>
                        <Input onChange={event => setEMAIL(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'language']} label='Language' rules={[{ required: true }]}>
                        <Select onSelect={value => setLanguage(value)} defaultValue="Select language" className="select-after">
                            {
                             languages ? languages.map((language: string) => <Option value={language}>{language}</Option>) : null
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name={['user', 'town']} label="Town" rules={[{ required: true }]}>
                        <Input onChange={event => setTown(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'company']} label="Company" rules={[{ required: true }]}>
                        <Input onChange={event => setCompany(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name={['user', 'jobTitle']} label="Job title">
                        <Input onChange={event => setJobTitle(event.target.value)}/>
                    </Form.Item>
                    <Form.Item name="website" label="Website">
                        <Input
                            onChange={event => setWebSite(event.target.value)}
                        />
                    </Form.Item>
                    <Form.Item name={['user', 'landline']} label="Landline" rules={[{ message: 'Please input your phone number!' }]}>
                        <Input
                            addonBefore={prefixLandline()}
                            onChange={event => setLandline(event.target.value)}
                        />
                    </Form.Item>
                    <Form.Item name={['user', 'mobile']} label="Mobile" rules={[{ message: 'Please input your mobile number!' }]}>
                        <Input
                            addonBefore={prefixLandline()}
                            onChange={event => setMobile(event.target.value)}
                        />
                    </Form.Item>
                </Form>
                <Title style={{fontSize: '1.6em', color: 'white'}}>Relationship</Title>
                <Form style={{display: 'flex', width: '100%'}}>
                    <div style={{width: '50%'}}>
                        <Title style={{fontSize: '1.4em',color: 'white'}}>MailShots:</Title>
                        <Radio.Group>
                            {
                                altyorMailShot ? altyorMailShot.map((element: any) => <Radio value={element.id} onChange={() => {
                                    setMailShort(element.id);
                                    setMailShortName(element.name);
                                }}>{element.name}</Radio >) : null
                            }
                        </Radio.Group>
                    </div>
                   <div style={{width: '50%'}}>
                       <Title style={{fontSize: '1.4em', color: 'white'}}>Category:</Title>
                       <Radio.Group>
                           <Radio value={1} onChange={() => {setAdditionalForm('Prospect'); setCategory('Prospect')}}>Prospect</Radio >)
                           <Radio value={2} onChange={() => {setAdditionalForm('Customer'); setCategory('Customer')}}>Customer</Radio >)
                           <Radio value={3} onChange={() => {setAdditionalForm('Partner'); setCategory('Partner')}}>Partner</Radio >)
                           <Radio value={4} onChange={() => {setAdditionalForm('Supplier'); setCategory('Supplier')}}>Supplier</Radio >)
                           <Radio value={5} onChange={() => {setAdditionalForm('Press'); setCategory('Press')}}>Press</Radio >)
                           <Radio value={6} onChange={() => {setAdditionalForm('HR'); setCategory('HR')}}>HR</Radio >)
                           <Radio value={7} onChange={() => {setAdditionalForm('Other'); setCategory('Other')}}>Other</Radio >)
                       </Radio.Group>
                   </div>
                </Form>
                <div style={{width: '100%'}}>
                    {showAdditionalForm(additionalForm)}
                </div>
                <span style={{width: '100%', display: 'flex', alignItems: 'center', margin: 5}}>
                    <Title style={{fontSize: '1.6em', color: 'white'}}>NoCrm</Title> <Switch onChange={() => setNoCrm(!noCrm)}/>
                </span>
                <div style={{width: '100%'}}>
                    {noCrm ? <NoCrmAltyor usersNoCrm={usersNoCrm} setSubject={setSubject} setAssign={setAssign}/> : null}
                </div>
                {success ? <Alert message="Success contact add." type="success" showIcon/> : null}
                {error ? <Alert message="Error the contact is not added." type="error" showIcon/> : null}
                <Button style={{backgroundColor: '#FF8900', color: 'white', borderColor: '#FF8900', margin: '2%'}} onClick={async () => {
                    try {
                        sentToApi()
                        setSuccess(true);
                        setTimeout(() => {
                            setSuccess(false);
                            window.location.reload(true);
                        },5000 );
                        gaEvent('Sending', 'The Altyor contact is sending');
                    } catch (e) {
                        setError(true);
                        gaEvent('Not sending', 'The Altyor contact isn\'t sending');
                        setTimeout(() => setError(false), 5000);
                    }
                }}>Submit</Button>
            </Col>
        </Row>
    );
};
