
interface memberMailChimp {
    status: string;
    email_address: string;
    merge_fields: {
        TITLE: string;
        FIRSTNAME: string;
        LASTNAME: string;
        COMPANY: string;
        WEBSITE?: string;
        JOBTITLE: string;
        TOWN: string;
        LANGUAGE: string;
        LANDLINE?: string;
        PHONE?: string;
        CATEGORY?: string;
        TYPE?: string;
        COMMENT?: string;
        PTITLE: string;
        ACTIVE: string;
    },
    interests?: any;
}

export const createMemberMailChimp = (config: memberMailChimp) => {

    let newMember = {
        status: config.status,
        email_address: config.email_address,
        merge_fields: {
            TITLE: config.merge_fields.TITLE,
            FIRSTNAME: config.merge_fields.FIRSTNAME,
            LASTNAME: config.merge_fields.LASTNAME,
            COMPANY: config.merge_fields.COMPANY,
            WEBSITE: config.merge_fields.WEBSITE,
            JOBTITLE: config.merge_fields.JOBTITLE,
            TOWN: config.merge_fields.TOWN,
            LANGUAGE: config.merge_fields.LANGUAGE,
            LANDLINE: config.merge_fields.LANDLINE,
            PHONE: config.merge_fields.PHONE,
            CATEGORY: config.merge_fields.CATEGORY,
            TYPE: config.merge_fields.TYPE,
            COMMENT: config.merge_fields.COMMENT,
            PTITLE: config.merge_fields.PTITLE,
            ACTIVE: config.merge_fields.ACTIVE,
        },
        interests: config.interests,
    }
    return newMember
}

interface NoCrm {
    title: string;
    description: string;
    tags: string;
    created_by_id?: number;
    user_id?: number
}


export const createMemberCrm = (config: NoCrm) => {

    let newMemberCrm = {
        title: config.title,
        description: config.description,
        tags: config.tags,
        created_by_id: config.created_by_id,
        user_id: config.user_id
    }
    return newMemberCrm
}

interface memberNodon {
    email_address: string;
    status: string;
    merge_fields: {
        PRENOM: string;
        NOM: string;
        ENTREPRISE?: string;
        FONCTION?: string;
        TELEPHONE?: string;
        MOBILE?: string;
        COMMENTAIR?: string;
        SOURCE: string;
        STATUT: string;
        MMERGE12: string;
    }
    interests: any;
}

export const createNodonMember = (config: memberNodon) => {

    let newNodonMember = {
        status: config.status,
        email_address: config.email_address,
        merge_fields: {
            PRENOM: config.merge_fields.PRENOM,
            NOM: config.merge_fields.NOM,
            ENTREPRISE: config.merge_fields.ENTREPRISE,
            FONCTION: config.merge_fields.FONCTION,
            TELEPHONE: config.merge_fields.TELEPHONE,
            MOBILE: config.merge_fields.MOBILE,
            COMMENTAIR: config.merge_fields.COMMENTAIR,
            SOURCE: config.merge_fields.SOURCE,
            STATUT: config.merge_fields.STATUT,
            MMERGE12: config.merge_fields.MMERGE12
        },
        interests: config.interests,
    }
    return newNodonMember
}

interface NoCrmNodon {
    title: string;
    description: string;
    tags: string[];
    created_by_id?: number;
    user_id?: number
}

export const createNodonNoCrm = (config: NoCrmNodon) => {

    let newNodonCrm = {
        title: config.title,
        description: config.description,
        tags: config.tags,
        created_by_id: config.created_by_id,
        user_id: config.user_id
    }
    return newNodonCrm
}

interface Notification {
    name : string;
    surname: string;
    email: string;
    NoCrm: string;
    company: string;
    title: string;
    category: string;
}

export const CreateNotification = (config: Notification) => {
    let data = {
        name: config.name,
        surname: config.surname,
        email: config.email,
        NoCrm: config.NoCrm,
        company: config.company,
        title: config.title,
        category: config.category,
    }
    return data
}