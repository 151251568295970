import React from 'react';
import './Header.scss';
import { Row, Col } from 'antd';
import { Typography } from 'antd';

const { Title } = Typography;

type Props = {
    user: any
}

export const Header: React.FC <Props> = ({user}) => (
    <Row className="header-home">
        <Col span={12} className="header-logo">
            <img className="logo-altyor" src={require('../../assets/logo_altyor.png')} alt="Logo-Altyor"/>
        </Col>
        <Col span={12} className="header-title">
            <div style={{display: 'flex', flexDirection: 'column', textAlign: "center"}}>
                <Title style={{margin: '5px'}} className="title-container">Altyor Le<em>ad</em></Title>
                <Title style={{margin: 0}} className="title-welcome">{`Welcome ${user.cn}`}</Title>
            </div>
        </Col>
    </Row>
);
